import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VExpansionPanels,{staticClass:"ExpansionComponent ExpansionBordered mt-3"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" Extensions and Endorsements "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('NewInsurancePolicyPro'),_c('ChangeOfInceptionDate'),_c('SummaryPro'),_c('div',{staticClass:"finishButtonCont mt-7 d-flex justify-end align-center"},[_c(VBtn,{staticClass:"finishBtn",attrs:{"rounded":"","large":"","text":""}},[_vm._v("Send To Facultative ")])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }